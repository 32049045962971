:root {
    --primary-color-1: #0C62F7;
    --text-primary: #0C62F7;
    --text-error: #C10808;
    --menu-active-text-color: #0C62F7;
    --menu-inactive-text-color: #696E80;
    --menu-small-title: #A3ADC1;
    --page-title: #0F2040;
    --card-bg: #FCFCFC;
    --text-visible: #0F2040;
    --text-invisible: #A3ADC1;
}

@font-face {
    font-family: 'AlbertSans-Bold';
    src: url('~assets/fonts/AlbertSans-Bold.ttf');
    src: url('~assets/fonts/AlbertSans-Bold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-Black';
    src: url('~assets/fonts/AlbertSans-Black.ttf');
    src: url('~assets/fonts/AlbertSans-Black.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-ExtraBold';
    src: url('~assets/fonts/AlbertSans-ExtraBold.ttf');
    src: url('~assets/fonts/AlbertSans-ExtraBold.ttf') format("truetype");
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-ExtraLight';
    src: url('~assets/fonts/AlbertSans-ExtraLight.ttf');
    src: url('~assets/fonts/AlbertSans-ExtraLight.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-Light';
    src: url('~assets/fonts/AlbertSans-Light.ttf');
    src: url('~assets/fonts/AlbertSans-Light.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-Medium';
    src: url('~assets/fonts/AlbertSans-Medium.ttf');
    src: url('~assets/fonts/AlbertSans-Medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-Regular';
    src: url('~assets/fonts/AlbertSans-Regular.ttf');
    src: url('~assets/fonts/AlbertSans-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-SemiBold';
    src: url('~assets/fonts/AlbertSans-SemiBold.ttf');
    src: url('~assets/fonts/AlbertSans-SemiBold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlbertSans-Thin';
    src: url('~assets/fonts/AlbertSans-Thin.ttf');
    src: url('~assets/fonts/AlbertSans-Thin.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gehuropic';
    src: url('~assets/fonts/Gehuropic.otf');
    src: url('~assets/fonts/Gehuropic.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Chinese Menu';
    src: url('~assets/fonts/字魂超级激战体-DemiBold.ttf');
    src: url('~assets/fonts/字魂超级激战体-DemiBold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'AlbertSans-Regular' !important;
    font-style: normal;
    line-height: 1 !important;
    touch-action: pan-x pan-y;
}

.font-ExtraBold {
    font-family: 'AlbertSans-ExtraBold';
}

.font-Bold {
    font-family: 'AlbertSans-Bold';
}

.font-Black {
    font-family: 'AlbertSans-Black';
}

.font-Regular {
    font-family: 'AlbertSans-Regular';
}

.font-SemiBold {
    font-family: 'AlbertSans-SemiBold';
}

.font-ExtraLight {
    font-family: 'AlbertSans-ExtraLight';
}

.font-Light {
    font-family: 'AlbertSans-Light';
}

.font-Thin {
    font-family: 'AlbertSans-Thin';
}

.font-Medium {
    font-family: 'AlbertSans-Medium';
}

.vh-100-row {
    min-height: 100vh;
}

.vh-80-row {
    min-height: 80vh;
}

.text-light-purple {
    color: #C3C0E4;
}

.text-light-purple-2 {
    color: #6D6B8A;
}

.text-light-purple-3 {
    color: #817EA4;
}

.text-purple {
    color: #484663;
}

.text-infoIndex-purple {
    color: #514E71;
}

::-webkit-scrollbar {
    width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent !important;
    border-radius: 50px;
    width: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
    width: 0px;
}

.headerTitle {
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    white-space: break-spaces;

    @media screen and (max-width: 991px) {
        font-size: 6vh;
    }

    @media screen and (max-width: 767px) {
        font-size: 4.5vh;
    }

    @media screen and (max-width: 300px) {
        font-size: 6vh;
    }
}

.sectionHeaderTitle {
    font-size: 3vw;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    white-space: break-spaces;

    @media screen and (max-width: 991px) {
        font-size: 3.5vh;
    }
}

.sectionHeaderTitle2 {
    font-size: 2.4vw;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    white-space: break-spaces;

    @media screen and (max-width: 991px) {
        font-size: 3vh;
    }
}

.sectionHeaderTitle3 {
    font-size: 1.8vw;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    white-space: break-spaces;

    @media screen and (max-width: 991px) {
        font-size: 2.3vh;
    }
}


.sectionDivTitle {
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: break-spaces;

    @media screen and (max-width: 991px) {
        font-size: 2vh;
    }
}

.sectionDesc {
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: normal;
    white-space: break-spaces;

    @media screen and (max-width: 991px) {
        font-size: 1.5vh;
    }

    @media screen and (max-width: 767px) {
        font-size: 1.5vh;
    }
}

.navbarText {
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: normal;
    white-space: nowrap;

    @media screen and (max-width: 991px) {
        font-size: 2vh;
        margin-bottom: 2vh;
        display: block;
    }

    @media screen and (max-width: 767px) {
        font-size: 2vh;
    }
}

.smallText {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    white-space: break-spaces;
    line-height: 120%;

    @media screen and (max-width: 991px) {
        font-size: 1.4vh;
    }
}

.vtgm-btn {
    cursor: pointer;
    width: fit-content;

    .btn {
        border-radius: 50%;
        width: 3vw;
        height: 3vw;
        border: 0.8px solid var(--Linear, #03FFE1);
        background: radial-gradient(80.87% 66.91% at 18.77% 85.71%, #1E233E 38.39%, #0C0E1A 100%);
        transition: 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2vh !important;

        @media screen and (max-width: 991px) {
            width: 5vh;
            height: 5vh;
            padding: 0.5vh !important;
        }

        .sectionDivTitle {
            opacity: 0;
            transition: 1s;
        }
    }

    span {
        margin-left: -1.5vw;
        transition: 1s;

        @media screen and (max-width: 991px) {
            margin-left: -2.5vh;
        }
    }

    &:hover {
        .btn {
            background: linear-gradient(121deg, #03FFE1 6.13%, #0349FF 50.38%, #8227FA 91.23%);
            transition: 1s;

            .sectionDivTitle {
                opacity: 1;
                transition: 1s;
            }
        }

        span {
            margin-left: 1vw;
            transition: 1s;

            @media screen and (max-width: 991px) {
                margin-left: 1vh;
            }
        }
    }
}

.vtgm-btn-transparent {
    cursor: pointer;
    width: fit-content;

    .btn {
        border-radius: 50%;
        width: 3vw;
        height: 3vw;
        border: 0.8px solid #fff;
        background: transparent;
        transition: 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2vh !important;

        @media screen and (max-width: 991px) {
            width: 5vh;
            height: 5vh;
            padding: 0.5vh !important;
        }

        .sectionDivTitle {
            opacity: 0;
            transition: 1s;
        }
    }

    span {
        margin-left: -1.5vw;

        @media screen and (max-width: 991px) {
            margin-left: -2.5vh;
        }
    }

    &:hover {
        .btn {
            .sectionDivTitle {
                opacity: 1;
                transition: 1s;
            }
        }

        span {
            margin-left: 1vw;
            transition: 1s;

            @media screen and (max-width: 991px) {
                margin-left: 1vh;
            }
        }
    }
}

.arrow-btn {
    cursor: pointer;
    width: fit-content;

    .btn {
        border-radius: 50%;
        width: 3vw;
        height: 3vw;
        border: 0.8px solid var(--Linear, #03FFE1);
        background: radial-gradient(80.87% 66.91% at 18.77% 85.71%, #1E233E 38.39%, #0C0E1A 100%);
        transition: 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2vh !important;

        @media screen and (max-width: 1200px) {
            width: 5vh;
            height: 5vh;
            padding: 0.5vh !important;
        }

        .sectionDivTitle {
            opacity: 1;
            transition: 1s;
        }
    }

    span {
        margin-left: -1.5vw;
        transition: 1s;

        @media screen and (max-width: 991px) {
            margin-left: -2.5vh;
        }
    }

    &:hover {
        .btn {
            background: linear-gradient(121deg, #03FFE1 6.13%, #0349FF 50.38%, #8227FA 91.23%);
            transition: 1s;

            .sectionDivTitle {
                opacity: 1;
                transition: 1s;
            }
        }

        span {
            margin-left: 1vw;
            transition: 1s;

            @media screen and (max-width: 991px) {
                margin-left: 1vh;
            }
        }
    }
}

.linearType1 {
    background: var(--Linear, linear-gradient(92deg, #03FFE1 0.42%, #0349FF 51.89%, #8227FA 99.4%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sliderArrowImg {
    width: 3.5vw;
    margin: 0 0.5vw;

    @media screen and (max-width: 991px) {
        width: 5vh;
        margin: 0 1vh
    }
}

.container {
    max-width: 80% !important;
    overflow-x: hidden;

    @media screen and (max-width: 991px) {
        max-width: 100% !important;
        padding: 0 !important;
    }
}

.menuToggleBtn {
    width: 5vw;
    z-index: 99;
    cursor: pointer;

    @media screen and (max-width: 991px) {
        width: 8vh;
    }
}

.pageSectionToggleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5C67A6;
    font-size: 2vh;
    padding: 1vh;
    color: white;
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 20%;
    z-index: 7;
}

.sideSectionBarSection {
    background: transparent;
    width: 40%;
    position: fixed;
    left: 0%;
    top: 0%;
    height: 100vh;
    z-index: 0;

    @media screen and (max-width: 991px) {
        top: 0;
        z-index: 8 !important;
        width: 100%;
        transform: translateX(-100vw);
        transition: 1s;
        background: linear-gradient(90deg, #000 70.4%, rgba(0, 0, 0, 0.00) 100%) !important;
        display: none;
    }

    .sideSectionBar {
        margin-left: 5%;
        margin-top: 18%;

        @media screen and (max-width: 991px) {
            margin-top: 12vh;
        }

        .sectionNameTextSection {
            @media screen and (max-width: 991px) {
                display: block !important;
            }
        }

        .sideBarSectionNumber {
            width: 2vw;
            height: 2vw;
            border: 1.5px solid #5C67A6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #5C67A6;
            cursor: pointer;

            @media screen and (max-width: 991px) {
                width: 3vh;
                height: 3vh;
            }
        }

        .sideDotDeco {
            width: 0.3vw;
            height: 0.3vw;
            background-color: #5C67A6;
            border-radius: 50%;
            margin: 0.6vw 0;

            @media screen and (max-width: 991px) {
                width: 0.5vh;
                height: 0.5vh;
                margin: 1vh 0;
            }
        }

        .sectionDesc {
            opacity: 0;
            transition: 1s;

            @media screen and (max-width: 991px) {
                opacity: 1 !important;
            }
        }
    }

    .closeSideBarBtn {
        border: 1px solid #C3C0E4;
        color: #C3C0E4;
        width: 4vh;
        height: 4vh;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating {
    0% {
        transform: translate(0px, 20px);
    }

    50% {
        transform: translate(0px, -20px);
    }

    100% {
        transform: translate(0px, 20px);
    }
}

.floating2 {
    animation-name: floating2;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating2 {
    0% {
        transform: translate(0px, -20px);
    }

    50% {
        transform: translate(0px, 20px);
    }

    100% {
        transform: translate(0px, -20px);
    }
}

.floating3 {
    animation-name: floating3;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating3 {
    0% {
        transform: translate(0px, -10px);
    }

    50% {
        transform: translate(0px, 10px);
    }

    100% {
        transform: translate(0px, -10px);
    }
}

.floating4 {
    animation-name: floating4;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating4 {
    0% {
        transform: translate(0px, 10px);
    }

    50% {
        transform: translate(0px, -10px);
    }

    100% {
        transform: translate(0px, 10px);
    }
}

#widget-ticker-tape-container {
    display: none;
}

.tradingview-widget-copyright {
    display: none;
}

.market-quotes-widget--transparent .market-quotes-widget__field--name-row-cell {
    backdrop-filter: blur(0) !important;
}

.sidebar-collapse .navbar-collapse:before {
    @media screen and (max-width: 991px)  {
        background: #000;
    }
}

.nav-open .sidebar-collapse .wrapper {
    @media screen and (max-width: 991px)  {
        transform: none;
    }
}

.languageDropdown .dropdown-toggle {
    margin: auto;
}

.languageDropdownPos {
    position: absolute !important;
    top: 3vh !important;
    right: 2vh !important;
    z-index: 999 !important;
}

.borderWhite {
    fill: #fff;
    border: 1px solid #fff;
    opacity: 1;
    width: 300%;
    margin-left: -100%;
}