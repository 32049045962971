.footer {
    background-image: url(../img/footer/footerBg.webp);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    @media screen and (max-width: 767px) {
        background-image: url(../img/footer/footerBgMobile.webp);
    }

    .footerPadding {
        padding: 3vh 0;
    }

    .footerLogoSize {
        width: 13vw;

        @media screen and (max-width: 991px) {
            width: 20vh;   
        }
    }

    a {
        text-decoration: none !important;
    }
}