.company {
    background-color: #0C0E1A;

    .header {
        background-image: url(../img/company/headerBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 25vh 0 45vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 20vh 0 40vh 0;
        }
    }

    .missionVision {
        .missionVisionBox {
            border-radius: 3vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 3vh 5vh 0vh 5vh;

            @media screen and (max-width: 991px) {
                padding: 5vh 0;
                border-radius: 3vh;
                padding: 2vh 5vh 2vh 5vh;
            }

            @media screen and (max-width: 767px) {
                border-radius: 3vh;
                padding: 2vh 2vh 2vh 2vh;
            }

            .missionVisionBoxImg {
                width: 80%;

                @media screen and (max-width: 991px) {
                    width: 50%;
                    margin-right: 0;
                }

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }

            .missionVisionBoxContent {
                padding-bottom: 20vh;

                @media screen and (max-width: 991px) {
                    padding-bottom: 14vh;
                }
            }
        }

        .sliderContentBox {
            .slick-dots {
                width: fit-content;
                bottom: 20%;
            }

            .slick-dots li {
                width: 8vw;
                height: 0.6vh;
                margin: 0 0.3vw;

                @media screen and (max-width: 991px) {
                    width: 15vw;
                }

                @media screen and (max-width: 300px) {
                    display: none;
                }
            }

            .slick-dots li button {
                width: 100%;
                height: 0.6vh;
                background-color: #404771;
                padding: 0;
                border-radius: 20vw;
            }

            .slick-dots .slick-active button {
                background: linear-gradient(110deg, #03FFE1 10.38%, #0349FF 52.66%, #8227FA 91.68%) !important
            }

            .slick-dots li button:before {
                content: none;
            }
        }

        .sliderArrowAbsolute {
            position: absolute;
            bottom: 20%;
            right: 0;

            @media screen and (max-width: 991px) {
                position: absolute;
                bottom: 20%;
                right: 0;
            }
        }
    }

    .advantage {
        background-image: url(../img/company/advantageBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 15vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }

        .advantage-row {
            margin-top: 5vh;
        }

        .advantage-row2 {
            @media screen and (max-width: 991px) and (min-width: 768px) {
                padding-top: 8vh;
            }
        }

        .advantageBox {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            padding: 0 2vh 10vh 2vh;

            @media screen and (max-width: 991px) {
                padding: 0 2vh 7vh 2vh;
                border-radius: 2vh;
                // margin-top: 8vh;
            }

            @media screen and (max-width: 767px) {
                padding: 0 2vh 7vh 2vh;
                border-radius: 2vh;
                margin-top: 8vh;
                height: auto !important;
            }
        }

        #advantageBox0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #advantageBox1 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #5C2727 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #advantageBox2 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        .advantageImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;
            margin: -5vh 0 3vh 2vh;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                padding: 2vh;
                margin: -5vh 0 2vh 0vh;
            }

            img {
                width: 3vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 4vh;
                }
            }
        }

        #advantageImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #advantageImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #advantageImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }
    }

    .license {
        background-image: url(../img/company/licenseBg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        .checkBg {
            height: 2vw;
            width: 2vw;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background: linear-gradient(110deg, #03FFE1 10.38%, #0349FF 52.66%, #8227FA 91.68%);
            box-shadow: 0px 4px 18px 0px #0379F8;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 991px) {
                height: 3vh;
                width: 3vh;
                margin-right: 2vw;
            }
        }

        .licenseBg {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(48.3% 96.02% at 49.29% 0%, #0033B9 0%, #1E233E 42.46%, #0C0E1A 100%);
            margin: 10vh 0 15vh 0;
            padding: 0 0 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }

            img {
                margin: auto;
                width: 10vw;
                margin-top: -10vh;
                padding-bottom: 5vh;

                @media screen and (max-width: 991px) {
                    width: 15vh;
                }
            }
        }
    }

    .liquidityPartner {
        .liquidityPartnerBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(50.09% 100% at 50% 0%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
            padding: 5vh 0;
            margin: 10vh 0 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }

            .announcementImg {
                margin-top: -10vh;
                width: 15vw;

                @media screen and (max-width: 991px) {
                    width: 25vh;
                }
            }

            .text-orange {
                color: #FF6600;
            }
        }

        .platformDevice2Box {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }

            img {
                width: 6vw;

                @media screen and (max-width: 991px) {
                    width: 9vh;
                }
            }
        }

        #platformDevice2Box0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #platformDevice2Box1 {
            background: radial-gradient(108.46% 98.61% at 27.34% 4.03%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }
    }

    .edgePrime {
        padding: 5vh 0;

        .edgePrimeInfoBox {
            border-radius: 3vw;
            border: 2px solid #2C3359;
            padding: 3vh 5vh;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
                padding: 3vh;
            }
        }

        #edgePrimeInfoBox0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #5C2727 0%, #1E233E 46.22%, #0C0E1A 100%);

            .sectionDivTitle {
                color: #8E4855;
            }
        }

        #edgePrimeInfoBox1 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);

            .sectionDivTitle {
                color: #8E6D30;
            }
        }

        #edgePrimeInfoBox2 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);

            .sectionDivTitle {
                color: #378A84;
            }
        }

        #edgePrimeInfoBox3 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);

            .sectionDivTitle {
                color: #2D5FE7;
            }
        }

        #edgePrimeInfoBox4 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);

            .sectionDivTitle {
                color: #7346B4;
            }
        }

        #edgePrimeInfoBox5 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #6B3079 0%, #1E233E 46.22%, #0C0E1A 100%);

            .sectionDivTitle {
                color: #9A4CAD;
            }
        }

        .slick-slide {
            padding: 0 0.5vw;
        }

        .slick-slide {
            height: auto !important;
            // padding: 1vh !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
            margin: 3vh 0;
        }
    }

    .resolution {
        background-image: url(../img/company/resolutionBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .complain {
        background-color: #0C0E1A;
        padding: 10vh 0 15vh 0;

        .complainBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 5vh 0;
            margin-bottom: 2vh;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        .complainImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;

            @media screen and (max-width: 991px) {
                border-radius: 1vh;
                padding: 1.5vh;
            }

            img {
                width: 2.5vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 3.5vh;
                }
            }
        }

        #complainImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #complainImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #complainImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        .descSpacing {
            margin-top: 6vh;
        }

        .complainBoxSection {
            padding: 3vh 0 8vh 0;

            @media screen and (max-width: 991px) {
                padding: 3vh 0;
            }
        }

        .slick-slide {
            height: auto !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }

    .certificate {
        background-image: url(../img/company/complainBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10vh 0 15vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }
}