.home {
    .header {
        background-image: url(../img/home/headerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-size: 200% auto;
            padding: 15vh 0 8vh 0;
        }

        @media screen and (max-width: 767px) {
            background-size: 300% auto;
            padding: 15vh 0 8vh 0;
        }

        .header-row {
            min-height: 100vh;

            @media screen and (max-width: 991px) {
                min-height: auto;
            }
        }

        .headerImgPos1 {
            position: relative;

            @media screen and (max-width: 991px) {
                position: absolute;
                left: -15%;
                top: 20%;
                max-width: 30% !important;
            }

            @media screen and (max-width: 767px) {
                position: absolute;
                left: -20%;
                top: 15%;
                max-width: 40% !important;
            }
        }

        .headerImgPos2 {
            position: relative;

            @media screen and (max-width: 991px) {
                position: absolute;
                right: -15%;
                top: 0%;
                max-width: 30% !important;
            }

            @media screen and (max-width: 767px) {
                position: absolute;
                right: -20%;
                top: 0%;
                max-width: 40% !important;
            }
        }

        .decoImg1 {
            width: 8%;
            position: absolute;
            top: 25%;
            right: 10%;
        }

        .decoImg2 {
            width: 15%;
            position: absolute;
            bottom: 10%;
            left: -15%;
        }
    }

    .powerExperience {
        background-image: url(../img/home/powerExperienceBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }

        .infoBox {
            border-radius: 1vw;
            border: 2px solid #2C3359;
            padding: 0 4vh 6vh 4vh;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                padding: 0 3vh 6vh 3vh;
            }

            img {
                width: 5vw;
                margin-top: -6vh;
                padding-bottom: 4vh;
                aspect-ratio: 1/1.6;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 8vh;
                }
            }
        }

        #infoBox0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #infoBox1 {
            background: radial-gradient(108.46% 98.61% at 27.34% 4.03%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #infoBox2 {
            background: radial-gradient(105.86% 96.47% at 28.14% 6.03%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #infoBox3 {
            background: radial-gradient(114.96% 101.13% at 22.58% 2.65%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        .infoBoxOuter {
            margin-top: 10vh;
        }
    }

    .whyChooseUs {
        background-image: url(../img/home/whyChooseUsBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        .whyChooseUsOptionSection {
            margin: 3vh 0;

            @media screen and (max-width: 767px) {
                margin: 0;
            }
        }

        .whyChooseUsOptionBoxActive {
            border-radius: 1vw;
            border: 2px solid #2C3359;
            background: radial-gradient(104.68% 100% at 50% 100%, #1E233E 38.39%, #0C0E1A 100%);
            text-align: center;
            padding: 1vh 0;
            cursor: pointer;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
            }

            img {
                width: 4vw;
                aspect-ratio: 1/1;
                object-fit: contain;
                margin: auto;
                filter: contrast(1);
                transition: 1s;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        .whyChooseUsOptionBox {
            border-radius: 1vw;
            border: 2px solid #2C3359;
            background: radial-gradient(104.68% 100% at 50% 100%, #1E233E 38.39%, #0C0E1A 100%);
            text-align: center;
            padding: 1vh 0;
            cursor: pointer;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
            }

            img {
                width: 4vw;
                aspect-ratio: 1/1;
                object-fit: contain;
                margin: auto;
                filter: contrast(0);
                transition: 1s;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        #whyChooseUsOptionBox0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyChooseUsOptionBox1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyChooseUsOptionBox2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyChooseUsOptionBox3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyChooseUsOptionBox4 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyChooseUsOptionBox5 {
            background: radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        .whyChooseUsContentBox {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 6vh 0;

            @media screen and (max-width: 991px) {
                padding: 4vh 0;
                border-radius: 2vh;
            }

            @media screen and (max-width: 767px) {
                padding: 10vh 0;
                border-radius: 2vh;
            }

            img {
                width: 8vw;
                aspect-ratio: 1/1;
                object-fit: contain;
                margin: auto;

                @media screen and (max-width: 991px) {
                    width: 10vh;
                }
            }
        }
    }

    .journey {
        background-image: url(../img/home/journeyBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .pamm {
        background-image: url(../img/home/pammBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-image: url(../img/home/pammBgMobile.webp);
            background-size: cover;
        }

        .header-row {
            min-height: 100vh;

            @media screen and (max-width: 991px) {
                min-height: 80vh;
            }
        }

        .absoluteBgLottie {
            position: absolute;
            top: 10%;
            left: 0;
            right: 0;
            margin: auto;
            height: 70%;
            opacity: 0.6;

            @media screen and (max-width: 991px) {
                top: 10%;
                width: 100%;
            }

            @media screen and (max-width: 767px) {
                top: 5%;
                width: 100%;
            }
        }
    }

    .startTrade {
        background-image: url(../img/home/startTradeBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        min-height: 100vh;
        padding: 10vh 0 20vh 0;

        .startTradeInfoSection {
            margin: 5vh 0 10vh 0;
        }

        .startTradeInfoBox {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            padding: 3vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
            }

            img {
                width: 4vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        #startTradeInfoBox0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #startTradeInfoBox1 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #startTradeInfoBox2 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        .startTradeBanner {
            background-image: url(../img/home/startTradeBannerBg.webp);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #0C0E1A;
            border-radius: 2vw;
            padding: 8vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                padding: 5vh 0;
            }
        }

        .slick-slide {
            height: auto !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }
}