.menu {
    background-image: url(../img/menu/menuBg.webp);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 8;
    width: 100%;
    box-sizing: border-box;
    transition: width 475ms ease-out, transform 450ms ease, border-radius .8s .1s ease;
    display: inline-block;

    @media screen and (max-width: 991px) {
        background-size: cover;
    }

    #homeHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 991px) {
            display: block;
            height: 100vh;
        }
    }

    .menuPanel {
        background-color: #0C0E1A;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #menuSection1 {
        transition: 1s;
    }

    #menuSection2 {
        transition: 1s;
    }

    .textTransEN {
        min-width: 7%;
        max-width: 7%;

        @media screen and (max-width: 991px) {
            min-width: 11%;
            max-width: 11%;
        }
    }

    .partnershipText {
        min-width: 7%;
        max-width: 7%;

        @media screen and (max-width: 991px) {
            min-width: 8%;
            max-width: 8%;
        }
    }


    .textTransZH {
        min-width: 10%;
        max-width: 10%;

        @media screen and (max-width: 991px) {
            min-width: 14%;
            max-width: 14%;
        }

        @media screen and (max-width: 767px) {
            min-width: 17%;
            max-width: 17%;
        }
    }

    .staggerTextBg {
        position: absolute;
        top: -20%;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            top: -20%;
        }

        @media screen and (max-width: 991px) {
            top: -20%;
        }

        @media screen and (max-width: 767px) {
            top: -15%;
        }

        .text-transition {
            width: 100%;
            height: 100vh !important;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                background: var(--Linear, linear-gradient(92deg, #03FFE1 0.42%, #0349FF 51.89%, #8227FA 99.4%)) !important;
                background-clip: text !important;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
            }
        }

        .textTransEN {
            div {
                font-family: 'Gehuropic';

                font-size: 8vw !important;

                @media screen and (max-width: 991px) {
                    font-size: 8vh !important;
                }

                @media screen and (max-width: 767px) {
                    font-size: 6vh !important;
                }
            }
        }

        .partnershipText {
            div {
                font-family: 'Gehuropic';

                font-size: 8vw !important;

                @media screen and (max-width: 991px) {
                    font-size: 7vh !important;
                }

                @media screen and (max-width: 767px) {
                    font-size: 5vh !important;
                }
            }
        }

        .textTransZH {
            div {
                font-family: 'Chinese Menu';

                font-size: 9vw !important;

                @media screen and (max-width: 991px) {
                    font-size: 9vh !important;
                }

                @media screen and (max-width: 767px) {
                    font-size: 8vh !important;
                }
            }
        }
    }

    .menu-slider-3d {
        margin: 5% auto 0 auto;

        @media screen and (max-width: 991px) {
            margin: 10% auto 0 auto;
        }

        div:nth-child(1) {
            div {
                opacity: 1 !important;

                span {
                    height: auto !important;
                }

                img {
                    aspect-ratio: 1/1.3 !important;
                    object-fit: contain !important;
                    width: 33vw !important;
                    margin: auto !important;

                    @media screen and (max-width: 1200px) {
                        width: 40vw !important;
                    }

                    @media screen and (max-width: 991px) {
                        width: 100% !important;
                    }
                }

                #animIcon2 {
                    @media screen and (max-width: 991px) {
                        padding-top: 10vh;
                    }
                }

                #animIcon3 {
                    aspect-ratio: 1/1.3 !important;
                    object-fit: contain !important;
                    width: 25vw !important;
                    margin: auto !important;

                    @media screen and (max-width: 1200px) {
                        width: 32vw !important;
                    }

                    @media screen and (max-width: 991px) {
                        padding-top: 10vh;
                        width: 80% !important;
                    }

                    @media screen and (max-width: 767px) {
                        width: 100% !important;
                    }
                }

                #animIcon4 {
                    aspect-ratio: 1/1.3 !important;
                    object-fit: contain !important;
                    width: 23vw !important;
                    margin: auto !important;

                    @media screen and (max-width: 1200px) {
                        width: 30vw !important;
                    }

                    @media screen and (max-width: 991px) {
                        width: 75% !important;
                        padding-top: 10vh;
                    }

                    @media screen and (max-width: 767px) {
                        width: 90% !important;
                    }
                }

                #animIcon5 {
                    aspect-ratio: 1/1.3 !important;
                    object-fit: contain !important;
                    width: 38vw !important;
                    margin: auto !important;

                    @media screen and (max-width: 1200px) {
                        width: 50vw !important;
                    }

                    @media screen and (max-width: 991px) {
                        width: 100% !important;
                        transform: scale(1.3);
                    }

                    @media screen and (max-width: 767px) {
                        transform: scale(1.5);
                    }
                }
            }

            div:nth-child(1) {
                width: 60%;

                @media screen and (max-width: 991px) {
                    width: 70%;
                    height: auto;
                }

                @media screen and (max-width: 767px) {
                    width: 50%;
                    height: auto;
                }
            }

            div:nth-child(2) {
                width: 70%;

                @media screen and (max-width: 1200px) {
                    width: 55%;
                }

                @media screen and (max-width: 991px) {
                    margin-top: 20%;
                    width: 70%;
                }
            }

            div:nth-child(3) {
                width: 60%;

                @media screen and (max-width: 767px) {
                    width: 50%;
                }
            }
        }

        .lottieFile {
            aspect-ratio: 1/1.3 !important;
            object-fit: contain !important;
            width: 33vw !important;
            margin: auto !important;

            @media screen and (max-width: 991px) {
                width: 60% !important;
            }

            @media screen and (max-width: 767px) {
                width: 80% !important;
            }
        }


        .lottieFile2 {
            aspect-ratio: 1/1.3 !important;
            object-fit: contain !important;
            width: 33vw !important;
            margin: auto !important;

            @media screen and (max-width: 1200px) {
                transform: scale(1.3);
            }

            @media screen and (max-width: 991px) {
                width: 60% !important;
                transform: scale(1.3);
            }

            @media screen and (max-width: 767px) {
                width: 80% !important;
                transform: scale(1.3);
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .menuDotsPos {
        width: fit-content;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 10%;
        z-index: 999;

        .sideBarSectionNumber {
            width: 2vw;
            height: 2vw;
            border: 1.5px solid #5C67A6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: #5C67A6;
            cursor: pointer;
            transition: 1s;

            &:hover {
                background-color: #fff;
                border: 1.5px solid #fff;
            }

            @media screen and (max-width: 991px) {
                width: 3vh;
                height: 3vh;
            }
        }

        .sideDotDeco {
            width: 0.3vw;
            height: 0.3vw;
            background-color: #5C67A6;
            border-radius: 50%;
            margin: 0.6vw 0;

            @media screen and (max-width: 991px) {
                width: 0.5vh;
                height: 0.5vh;
                margin: 1vh 0;
            }
        }
    }

    .navigationPos {
        width: 100%;

        .leftArrowPos {
            position: absolute;
            top: 50%;
            left: 25%;
            z-index: 100;

            @media screen and (max-width: 991px) {
                top: 50%;
                left: 5%;
            }
        }

        .rightArrowPos {
            position: absolute;
            top: 50%;
            right: 25%;
            z-index: 100;

            @media screen and (max-width: 991px) {
                top: 50%;
                right: 5%;
            }
        }
    }
}