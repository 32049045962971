.logo-header {
    width: 12vw;
    margin: auto;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    
    @media screen and (max-width: 991px) and (orientation:portrait) {
        top: 10%;
        width: 30%;
    }

    @media screen and (max-width: 991px) and (orientation:landscape) {
        width: 10%;
    }

    @media screen and (max-width: 667px) {
        width: 40%;
        top: 15%;
    }
}

.navbar-vtgm {
    background-color: #0C0E1A;
}

.navbar {
    @media screen and (min-width: 992px) {
        padding-top: 1.5vw !important;
        padding: 1.8vw 2vw !important;
        height: 4.7vw !important;
    }

    // @media screen and (max-width: 991px) {
    //     height: 8vh !important;
    // }
}

.navbar-translate {
    
    @media screen and (max-width: 991px) {
        width: 100%;
    }
}

.sidebar-hamburger {
    z-index: 999;
}

@mixin transition {
    @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
            transition: transform 1s #{$i * .08}s cubic-bezier(.29, 1.4, .44, .96);
        }
    }
}

@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

.navbar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

$main: #1184F0;
$text: #FEFEFE;
$contrast: darken($main, 10%);

.msg {
    font-family: 'Pacifico', cursive;
    font-size: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    align-items: center;
    color: $text;
    background-color: $main;
}

.menuNavBarTextPos {
    position: absolute;
    left: -3%;
    top: 25%;
    transform: rotate(90deg);

    span {
        color: #091938;
        font-family: 'Proximate-Regular';
        font-size: 7vw;
        font-style: normal;
        font-weight: 400;
        line-height: 118.945%;
        letter-spacing: 7.2px;
        text-transform: uppercase;
    }
}

.drawer-list {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    transform: translate(100%, 0);
    /* ie workaround */
    -ms-transform: translatex(-100%);
    box-sizing: border-box;
    pointer-events: none;
    padding-top: 15vh;
    transition: width 475ms ease-out, transform 450ms ease, border-radius .8s .1s ease;
    border-bottom-left-radius: 100vw;
    backdrop-filter: blur(40px);
    // background: rgba(5, 1, 14, 0.8);
    // background-image: url(../img/logo/navbarBg.webp);
    background-size: 100% 100%;

    @media screen and (max-width: 991px) {
        padding-top: 120px;
    }

    @media (min-width: 768px) {
        width: 100vw;
    }

    ul {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        overflow-x: hidden;
        pointer-events: auto;
    }

    li {
        list-style: none;
        text-transform: uppercase;
        pointer-events: auto;
        white-space: nowrap;
        box-sizing: border-box;
        transform: translatex(100vw);
        -ms-transform: translatex(-100vw);

        &:last-child {
            margin-bottom: 1em;
        }

        a {
            text-decoration: none;
            text-align: center;
            display: block;
            padding: 1rem;
            color: $text;

            @media (min-width: 768px) {
                text-align: right;
                padding: .5rem;
            }

            &:hover {
                cursor: pointer;
                // @include alpha-attribute('background-color', rgba($main, 0.5), white);
            }
        }
    }
}

.drawer-list-inner {
    height: 1000px;
    overflow-y: scroll;
    // background-color: red;
}

.hamburger-container {
    // position: fixed;
    // top: 1.625rem;
    // right: 15px;

    @media screen and (max-width: 767px) {
        top: 0.5rem;
        right: 15px;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        top: 0.5rem;
        right: 15px;
    }

    input.hamburger {
        display: none;

        &:checked {
            &~.drawer-list {
                transform: translatex(0);
                border-bottom-left-radius: 0;

                li {
                    transform: translatex(0);
                    @include transition;

                    a {
                        padding-right: 15px;
                    }
                }

                @media screen and (max-width: 991px) {
                    transform: translate(0);
                }
            }

            &~label {
                >i {
                    background-color: #fff;
                    transform: rotate(45deg);
                    width: 100%;

                    &:before {
                        background-color: transparent;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    &:after {
                        width: 100%;
                        background-color: #fff;
                        transform: translate(-0%, -0%) rotate(-90deg);
                    }
                }

                close {
                    color: $text;
                    width: 100%;
                }

                open {
                    color: rgba(0, 0, 0, 0);
                    width: 0;
                }
            }
        }
    }

    label.hamburger {
        z-index: 9999;
        position: relative;
        display: block;
        width: 10vh;
        width: 3vw;
        height: 3vh;

        @media screen and (max-width: 767px) {
            width: 10vw;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            width: 5vw;
        }

        &:hover {
            cursor: pointer;
        }

        text {

            close,
            open {
                text-transform: uppercase;
                font-size: .8em;
                align-text: center;
                position: absolute;
                transform: translateY(50px);
                text-align: center;
                overflow: hidden;
                transition: width .25s .35s, color .45s .35s;
            }

            close {
                color: rgba(0, 0, 0, 0);
                right: 0;
                width: 0;
            }

            open {
                color: $text;
                width: 100%;
            }
        }

        >i {
            position: absolute;
            width: 50%;
            height: 0.5vh;
            top: 50%;
            right: 0;

            pointer-events: auto;
            transition-duration: .35s;
            transition-delay: .35s;
            background-color: #f3fff3;

            @media screen and (max-width: 991px) and (orientation:landscape) {
                width: 50%;
            }

            @media screen and (max-width: 991px) {
                height: 2px;
            }

            &:before,
            &::after {
                background-color: #f3fff3;
                position: absolute;
                display: block;
                width: 200%;
                height: 0.5vh;
                left: 0%;
                content: "";
                transition: transform 0.35s;
                transform-origin: 50% 50%;

                @media screen and (max-width: 991px) {
                    height: 2px;
                }
            }

            &:before {
                display: none;
                transform: translate(-50%, -1vh);
            }

            &:after {
                transform: translate(-50%, 1vh);
            }
        }
    }
}


.icon {
    display: inline-block;
    width: 5vw;
    height: 4vw;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.btn-login {
    border-radius: 5px;
    padding: 0.7vw 2vw !important;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.25vw;
    color: #fff;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: 2px solid var(--Linear, #765B4C);
    background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
    box-shadow: none;
    text-transform: capitalize;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        font-size: 2.833vw;
        line-height: 105%;
        padding: 0.5rem 1rem !important;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.833vw;
        line-height: 105%;
        padding: 0.5rem 2rem !important;
    }
}

.btn-register {
    border-radius: 5px;
    font-weight: 500;
    padding: 0.7vw 2vw !important;
    font-size: 0.833vw;
    line-height: 1.25vw;
    color: #fff;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: 2px solid var(--Linear, #765B4C);
    background: transparent;
    box-shadow: none;
    text-transform: capitalize;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        font-size: 2.833vw;
        line-height: 105%;
        padding: 0.5rem 1rem !important;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 1.833vw;
        line-height: 105%;
        padding: 0.5rem 2rem !important;
    }
}

.hoverEffectNavText {
    width: 3vw;
    height: 4vw;
    background: linear-gradient(rgba(5, 168, 232, 0.43) 0%, rgba(0, 84, 184, 0.19) 99.99%, rgba(0, 83, 183, 0.00) 100%);
    position: absolute;
    border-radius: 40%;
    filter: blur(8px);
    margin: 0 10px;
    z-index: 0;
    transform: translateX(200%);
    opacity: 0;
    animation: hoverDefaultNavEffect 1s;
}

@keyframes hoverDefaultNavEffect {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }

    100% {
        transform: translateX(200%);
        opacity: 0;
    }
}

.drawer-list-group {
    li {
        a {
            color: $text;
            transition: 1s;
            position: sticky;
            z-index: 1;
        }

        &:hover {
            // background-image: url('../img/background/listBg.webp');
            // background-repeat: no-repeat;
            // background-size: 100% 100%;

            a {
                color: #08D1FF !important;
            }

            .hoverEffectNavText {
                animation: hoverNavEffect 1s;
                transform: translateX(0%);
                opacity: 1;
            }

            @keyframes hoverNavEffect {
                0% {
                    transform: translateX(200%);
                    opacity: 0;
                }

                100% {
                    transform: translateX(0%);
                    opacity: 1;
                }
            }

            svg {
                color: #08D1FF !important;
            }
        }
    }
}

.navbar-translate {
    z-index: 9999 !important;
}

.navbar-nav {
    z-index: 9999 !important;
}

.react-switch {
    z-index: 9999 !important;

    &.light {
        .react-switch-bg {
            background: linear-gradient(270deg, rgba(0, 54, 107, 0.2) 0%, rgba(0, 112, 154, 0) 75%), #FFFFFF !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        }
    }
}

.top-menu {
    overflow: visible !important;

    li {
        display: inline;
    }
}

.mobile-menu-list {
    li {
        // border-bottom: 1px solid #2e3f52;
        cursor: pointer;
    }



    li a {
        display: block;
        width: inherit;
        position: relative;
        line-height: 20px;
        height: 40px;
        padding: 10px;
        transition: all .51s ease;

        // color: red !important;
        &.dark {
            color: #ffffff !important;
        }

        &.light {
            color: #575757 !important;
        }
    }

    .godwin {
        background-color: #28394A;
    }

    li>a:hover {
        // background-image: url('../../assets/img/background/menu-active.webp');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top;
        // background-color: #36cde8;
        color: #08D1FF;
    }

    .dropdown-mobile-submenu>a:hover {
        // background-color: #0bb8d3;
        // color: #637991;
        // background-image: url('../../assets/img/background/menu-active.webp');
        background-repeat: no-repeat;
        background-size: cover;
        // background-color: #36cde8;
        color: #08D1FF;
    }

    a {
        text-decoration: none !important;
    }

    .dropdown-mobile-submenu,
    .dropdown-mobile {
        a {
            &.active {
                svg {
                    // transform: rotate(-180deg);
                }
            }
        }

        svg {
            top: 5px;
            position: relative;
        }
    }

    .dropdown-mobile-menu {
        margin: 0px;
        padding: 0px;
    }

    .dropdown-mobile-menu li {
        background-color: transparent;

        &:last-child {
            margin-bottom: 1em;
        }
    }

    .level-1,
    .level-2,
    .level-3,
    .level-4 {
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.52) inset;
    }

    .level-1 a:before {
        content: "  ";
        white-space: pre;
    }

    .level-2 a:before {
        content: "    ";
        white-space: pre;
    }

    .level-3 a:before {
        content: "      ";
        white-space: pre;
    }

    .level-4 a:before {
        content: "        ";
        white-space: pre;
    }

    .level-1 {
        // background-color: #202f40;
        // background-image: url('../img/background/submenu-active.webp');
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        margin-top: 1em;
    }

    .level-2 {
        // background-color: #182838;
        margin-top: 1em;
    }

    .level-3 {
        background-color: #122436;
    }

    .level-4 {
        background-color: #0e2031;
    }
}

.switch-mobile {
    position: absolute;
    bottom: 5%;
    left: 5%;
}

.language-mobile {
    .language-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        cursor: pointer;
        overflow: hidden;

        &:hover,
        &:active,
        &:focus {
            background: transparent;
            border: 1px solid transparent;
            outline: none;
        }
    }
}

.dropdown-mobile .language-options {
    // margin: 5px 0 0;
    // padding: 1rem 0;
    border-radius: 3px;
    border: 1px solid #000;
    box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.15);
    width: 100%;
}

.language-options--opened {
    display: block;
}

.language-list {
    margin: 0;
    height: 100%;
}

.language-option {
    display: flex;
    align-items: center;
    // padding: 0.5rem 1rem;
    cursor: pointer;
    // font-size: 1.125rem;
    // font-weight: 400;
    transition: ease 0.25s;
}

.language-option:hover {
    background: #08D1FF;
    border-radius: 5px;
}

.language-option:hover .language-checkbox {
    background: #fff;
}

.mt-xl-10vh {
    @media screen and (min-width: 1800px) {
        margin-top: 10vh;
    }
}

.mt-xl-5vh {
    @media screen and (min-width: 1800px) {
        margin-top: 5vh;
    }
}


@media screen and (min-width: 992px) {
    .navbar-nav .nav-item:not(:last-child) {
        margin-right: 2vw;
    }
}

.lang-dropdown-container .lang-menu-container {
    &::-webkit-scrollbar {
        width: .4em;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, .2);
    }
}

#google_translate_element {
    span {
        display: none;
    }

    // ::-webkit-scrollbar {
    //     width: 10px;
    // }

    // /* Track */
    // ::-webkit-scrollbar-track {
    //     background: rgba(64, 0, 255, 0.8);
    //     border-radius: 50px;
    // }

    // /* Handle */
    // ::-webkit-scrollbar-thumb {
    //     background: rgba(254, 1, 1, 0.5);
    //     border-radius: 50px;
    // }

    // /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //     background: #f70000;
    // }
}

#google_translate_element2 {
    span {
        display: none;
    }
}

.goog-te-gadget {
    font-size: 0 !important;
    color: transparent !important;
}

.darkModeLang .goog-te-combo {
    background-color: transparent;
    color: white;
    border: none;

    option {
        background-color: black;
    }

}

.lightModeLang .goog-te-combo {
    background-color: transparent;
    color: black;
    border: none;

    option {
        background-color: white;
    }
}

.languageDropdown {
    position: sticky !important;
    z-index: 10 !important;

    .dropdown-toggle {
        background-color: rgb(147, 147, 147);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px !important;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        background-color: #000 !important;
        border-radius: 1vw !important;
        height: auto !important;
        
        span {
            color: #fff !important;
        }
    }

    .dropdown-menu:before {
        display: none;
    }
}
