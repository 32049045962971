.research {
    background-color: #0C0E1A;

    .header {
        background-image: url(../img/research/headerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 15vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 20vh 0 10vh 0;
        }
    }

    .calculator {
        padding: 10vh 0;

        .calculatorBoxDiv {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 8vh 5vh;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                padding: 5vh 2vh;
            }

            .input-transparent {
                border: none !important;
                background-color: transparent !important;
                background: transparent !important;
                font-weight: 600 !important;
                border-radius: 1vw !important;
                border: 2px solid #2C3359 !important;
                background: #1E233E !important;
                height: auto !important;

                @media screen and (max-width: 991px) {
                    border-radius: 1vh !important;
                }
            }

            .errorTextRed {
                color: red;
            }

            .borderLine {
                width: 100%;
                height: 1px;
                background-color: #3A4376;
                opacity: 1;
                margin: 5vh 0;
            }
        }

        input[type=number]::-webkit-inner-spin-button {
            opacity: 0 !important;
        }

        .spinner-button {
            position: absolute;
            cursor: default;
            text-align: center;
            margin: 0px;
        }

        #inc-button {
            right: 3%;
            top: 26%;
            cursor: pointer;
        }

        #dec-button {
            left: 3%;
            top: 26%;
            cursor: pointer;
        }
    }

    .calendar {
        background-image: url(../img/research/calendarBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10vh 0;
    }

    .apiSection {
        .apiSectionBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            min-height: 30vh;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
                min-height: 30vh;
            }
        }

        .discoverToolsBanner {
            background-image: url(../img/research/discovderToolsBg.webp);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #0C0E1A;
            border-radius: 2vw;
            padding: 5vh 4vh;
            margin-top: 6vh;

            @media screen and (max-width: 991px) {
                background-size: cover;
                padding: 5vh 2vh;
                margin-top: 4vh;
                border-radius: 3vh;
            }
        }
    }

    .discoverTools {
        padding-bottom: 10vh;

        .apiSectionBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            min-height: 30vh;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
                min-height: 30vh;
            }
        }
    }
}