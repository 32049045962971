.platform {
    background-color: #0C0E1A;

    .header {
        background-image: url(../img/platform/headerBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #0C0E1A;
        padding: 18vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .platformDevice {
        padding: 10vh 0;

        .platformDeviceBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
            }
        }

        .platformDeviceImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
                padding: 1vh;
            }

            img {
                width: 2.5vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        #platformDeviceImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #platformDeviceImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #platformDeviceImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #platformDeviceImgDiv3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        .platformDevice2Box {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }

            img {
                width: 6vw;

                @media screen and (max-width: 991px) {
                    width: 9vh;
                }
            }
        }

        #platformDevice2Box0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #platformDevice2Box1 {
            background: radial-gradient(108.46% 98.61% at 27.34% 4.03%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }
    }

    .webTrader {
        background-image: url(../img/platform/webTraderBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 17vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .webTraderSlider {
        padding: 5vh 0;

        .webTraderBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            // padding: 5vh 0;
            height: 90% !important;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        .webTraderBoxSection {
            margin: 5vh 0;
            display: flex;
            justify-content: center;
            align-items: stretch;
            height: 100%;
        }

        .webTraderImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;
            margin-top: -5vh;

            @media screen and (max-width: 991px) {
                padding: 1vh;
                border-radius: 1.5vh;
            }

            img {
                width: 3vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        #webTraderBox0 {
            background: radial-gradient(89.27% 100% at 50% 0%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #webTraderImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #webTraderBox1 {
            background: radial-gradient(89.27% 100% at 50% 0%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #webTraderImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #webTraderBox2 {
            background: radial-gradient(89.27% 100% at 50% 0%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #webTraderImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #webTraderBox3 {
            background: radial-gradient(89.27% 100% at 50% 0%, #5C2727 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #webTraderImgDiv3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #webTraderBox4 {
            background: radial-gradient(89.27% 100% at 50% 0%, #6B3079 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #webTraderImgDiv4 {
            background: var(--Magenta, radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%));
        }

        #webTraderBox5 {
            background: radial-gradient(89.27% 100% at 50% 0%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #webTraderImgDiv5 {
            background: var(--Orange, radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%));
        }

        .slick-slide {
            height: auto !important;
            // padding: 1vh !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
            margin: 5vh 0;
        }
    }

    .platformPamm {
        background-image: url(../img/platform/pammBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 18vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }

        .absoluteBgLottie {
            position: absolute;
            top: 10%;
            left: 0;
            right: 0;
            margin: auto;
            height: 80%;
            opacity: 0.6;

            @media screen and (max-width: 991px) {
                top: 10%;
                width: 100%;
            }

            @media screen and (max-width: 767px) {
                top: 20%;
                width: 100%;
            }
        }
    }

    .pammContent {
        padding: 10vh 0;

        .pammContentBox {
            margin: 10vh 0 5vh 0;
            border-radius: 3vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 3vh 0 5vh 0;

            .lottieFile {
                width: 80%;
                margin: -10vh auto auto auto;
            }
        }

        .pammIconBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 3vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
            }
        }

        .pammIconImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;

            @media screen and (max-width: 991px) {
                padding: 1vh;
                border-radius: 1.5vh;
            }

            img {
                width: 2.5vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 3.5vh;
                }
            }
        }

        #pammIconImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #pammIconImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #pammIconImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #pammIconImgDiv3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #pammIconImgDiv4 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        .descSpacing {
            margin-top: 2vh;
        }

        .pammIconBoxSection {
            padding: 3vh 0 8vh 0;
        }

        .col-lg-fit {
            @media screen and (min-width: 992px) {
                max-width: 20%;
            }
        }

        .slick-slide {
            height: auto !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }

    .copyTrader {
        background-image: url(../img/platform/copyTraderBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 15vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .advantageCopier {
        padding: 10vh 0;

        .advantageCopierBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                padding: 5vh 2vh;
            }
        }

        #advantageCopierBox0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #174CDB;
            }
        }

        #advantageCopierBox1 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #7345B4;
            }
        }

        #advantageCopierBox2 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #378A84;
            }
        }

        #advantageCopierBox3 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #8E6D30;
            }
        }

        #advantageCopierBox4 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #5C2727 0%, #1E233E 46.22%, #0C0E1A 100%), radial-gradient(109.78% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #8E4855;
            }
        }

        #advantageCopierBox5 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #6B3079 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #9A4CAD;
            }
        }

        .slick-slide {
            height: auto !important;
            padding: 1vh !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }
}