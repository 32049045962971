.product {
    background-color: #0C0E1A;

    .cfdHeader {
        background-image: url(../img/product/cfdHeaderBg.webp);
        background-size: 100% 70%;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #0C0E1A;
        padding: 0 0 14vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: bottom;
        }

        .cfdTitleSection {
            margin-bottom: 10vh;
        }
    }

    .cfdContent {
        .slick-slide {
            height: auto !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
        
        .longShortCFDDiv {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(91.62% 100% at 50% 0%, #582D8F 0%, #1E233E 28.48%, #0C0E1A 100%);
            padding: 6vh 0;
            margin: 4vh 0;

            @media screen and (max-width: 991px) {
                padding: 4vh 0;
                border-radius: 2vh;
            }
        }

        .cfdTableDiv {
            border-radius: 3vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 2vh 5vh;

            @media screen and (max-width: 991px) {
                padding: 0vh 3vh;
                border-radius: 2vh;
            }

            .table td,
            .table th {
                border-top: none !important;
            }

            .table thead th {
                border-bottom: 1px solid #3A4376;
            }
        }

        .cfdWorkSection {
            padding: 5vh 0 7vh 0;

            .cfdWorkBox {
                border-radius: 2vw;
                border: 2px solid #2C3359;
                background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
                padding: 5vh 0;

                @media screen and (max-width: 991px) {
                    border-radius: 2vh;
                    padding: 4vh 0;
                }
            }

            .cfdWorkImgDiv {
                border-radius: 1.5vw;
                border: 2px solid #2C3359;
                width: fit-content;
                padding: 1vw;

                @media screen and (max-width: 991px) {
                    border-radius: 1.5vh;
                    padding: 1vh;
                }

                img {
                    width: 2.5vw;
                    aspect-ratio: 1/1;
                    object-fit: contain;

                    @media screen and (max-width: 991px) {
                        width: 3.5vh;
                    }
                }
            }

            #cfdWorkImgDiv0 {
                background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #cfdWorkImgDiv1 {
                background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #cfdWorkImgDiv2 {
                background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            .cfdWorkBoxSection {
                padding: 4vh 0;
            }
        }

        .cfdBenefitSection {
            padding: 5vh 0;

            .cfdBenefitBox {
                border-radius: 2vw;
                border: 2px solid #2C3359;
                background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
                height: 80%;

                @media screen and (max-width: 991px) {
                    border-radius: 2vh;
                    height: 75%;
                }
            }

            .cfdBenefitBoxSection {
                margin: 8vh 0;
            }

            .cfdBenefitImgDiv {
                border-radius: 1.5vw;
                border: 2px solid #2C3359;
                width: fit-content;
                padding: 1vw;
                margin-top: -5vh;

                @media screen and (max-width: 991px) {
                    border-radius: 1.5vh;
                    padding: 1vh;
                    margin-top: -4vh;
                }

                img {
                    width: 3vw;
                    aspect-ratio: 1/1;
                    object-fit: contain;

                    @media screen and (max-width: 991px) {
                        width: 5vh;
                    }
                }
            }

            #cfdBenefitBox0 {
                background: radial-gradient(89.27% 100% at 50% 0%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
            }

            #cfdBenefitImgDiv0 {
                background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #cfdBenefitBox1 {
                background: radial-gradient(89.27% 100% at 50% 0%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
            }

            #cfdBenefitImgDiv1 {
                background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #cfdBenefitBox2 {
                background: radial-gradient(89.27% 100% at 50% 0%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
            }

            #cfdBenefitImgDiv2 {
                background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
            }
        }

        .cfdGoFurther {
            padding: 5vh 0;

            .goFurtherBox {
                border-radius: 3vw;
                border: 2px solid #2C3359;
                background: radial-gradient(50.09% 100% at 50% 0%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
                margin-top: 10vh;
                padding: 5vh 0;

                @media screen and (max-width: 991px) {
                    border-radius: 2vh;
                }

                img {
                    margin-top: -10vh;
                }
            }
        }

        .cfdAssetTrade {
            padding: 5vh 0;

            .assetTradeBox {
                border-radius: 2vw;
                border: 2px solid #2C3359;
                background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
                padding: 3vh 0 5vh 0;

                @media screen and (max-width: 991px) {
                    border-radius: 2vh;
                }
            }

            .assetTradeImgDiv {
                border-radius: 1.5vw;
                border: 2px solid #2C3359;
                width: fit-content;
                padding: 1vw;

                @media screen and (max-width: 991px) {
                    padding: 1vh;
                    border-radius: 1.5vh;
                }

                img {
                    width: 2.5vw;
                    aspect-ratio: 1/1;
                    object-fit: contain;

                    @media screen and (max-width: 991px) {
                        width: 4vh;
                    }
                }
            }

            #assetTradeImgDiv0 {
                background: var(--Green, radial-gradient(104.68% 100% at 50% 100%, #286932 0%, #1E233E 38.39%, #0C0E1A 100%));
            }

            #assetTradeImgDiv1 {
                background: var(--Magenta, radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%));
            }

            #assetTradeImgDiv2 {
                background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #assetTradeImgDiv3 {
                background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #assetTradeImgDiv4 {
                background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            #assetTradeImgDiv5 {
                background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
            }

            .descSpacing {
                margin-top: 6vh;
            }

            .assetTradeBoxSection {
                padding: 3vh 0 8vh 0;
            }

            .slick-slide {
                height: auto !important;
                padding: 1vh !important;
            }

            .slick-slide>div:first-child {
                height: 100%;
            }

            .slick-track {
                display: flex;
                align-items: stretch;
            }
        }

        .chooseingCFD {
            background-image: url(../img/product/choosingCFDBg.webp);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #0C0E1A;
            padding: 10vh 0;

            .row {
                min-height: 60vh;
            }
        }

        .getInTouchBanner {
            background-image: url(../img/home/startTradeBannerBg.webp);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #0C0E1A;
            border-radius: 2vw;
            padding: 8vh 5vh;

            @media screen and (max-width: 991px) {
                background-size: cover;
                border-radius: 3vh;
                padding: 6vh 3vh;
            }
        }
    }

    .forexHeader {
        background-image: url(../img/product/forexHeaderBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #0C0E1A;
        min-height: 80vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            min-height: auto;
        }

        .forexHeaderRow {
            min-height: 80vh;

            @media screen and (max-width: 991px) {
                min-height: auto;
                padding: 20vh 0 10vh 0;
            }
        }
    }

    .indicesHeader {
        background-image: url(../img/product/indicesHeaderBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #0C0E1A;
        min-height: 80vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            min-height: auto;
        }

        .forexHeaderRow {
            min-height: 80vh;

            @media screen and (max-width: 991px) {
                min-height: auto;
                padding: 20vh 0 10vh 0;
            }
        }
    }

    .forexContent {
        .contentWhyBox {
            padding: 3vh;
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
            }
        }

        .contentLiveChartBox {
            padding: 3vh;
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            min-height: 30vh;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                min-height: 25vh;
            }
        }
    }
}