.trading {
    .header {
        background-image: url(../img/trading/headerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 991px) {
            padding: 20vh 0;
        }
    }
}