.partnership {
    background-color: #0C0E1A;

    .header {
        background-image: url(../img/partnership/headerBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0 0 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 5vh 0 0 0;
        }

        .partnershipHeaderBoxMB {
            margin-bottom: 0;

            @media screen and (max-width: 991px) {
                margin-bottom: 10vh;
            }
        }

        .partnershipHeaderBox {
            border-radius: 3vw;
            border: 2px solid #2C3359;
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }

            img {
                width: 8vw;
                margin-top: -6vw;
                padding-bottom: 2vh;

                @media screen and (max-width: 991px) {
                    width: 12vh;
                    margin-top: -10vh;
                }
            }
        }

        #partnershipHeaderBox0 {
            background: radial-gradient(96.25% 97.24% at 29.77% 4.88%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #partnershipHeaderBox1 {
            background: radial-gradient(96.25% 97.24% at 29.77% 4.88%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #partnershipHeaderBox2 {
            background: radial-gradient(105.86% 96.47% at 28.14% 6.03%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #partnershipHeaderBox3 {
            background: radial-gradient(108.46% 98.61% at 27.34% 4.03%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);
        }
    }

    .familyMember {
        background-image: url(../img/partnership/familyMemberBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 15vh 0 5vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 15vh 0 10vh 0;
        }

    }

    .brokerStep {
        background-image: url(../img/partnership/brokerStepBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 15vh 0 15vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 5vh 0 15vh 0;
        }

        .brokerStepBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 2vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        .brokerStepImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
                padding: 1vh;
            }

            img {
                width: 2.5vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 3.5vh;
                }
            }
        }

        #brokerStepBox3 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #674F20 0%, #0C0E1A 100%);
            border: 2px solid #674F20;

            .sectionDivTitle {
                color: #77603B !important;
            }
        }

        #brokerStepImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #brokerStepImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #brokerStepImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #brokerStepImgDiv3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #0C0E1A 100%);
        }

        .descSpacing {
            margin-top: 6vh;
        }

        .brokerStepBoxSection {
            padding: 3vh 0 8vh 0;
        }

        .slick-slide {
            height: auto !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }

    .brokerContactBaner {
        background-image: url(../img/home/startTradeBannerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        border-radius: 2vw;
        padding: 8vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 5vh 2vh;
            border-radius: 3vh;
        }
    }

}