.accountOverview {
    background-color: #0C0E1A;

    .header {
        background-image: url(../img/accountOverview/headerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 25vh 0;

        @media screen and (max-width: 991px) {
            padding: 20vh 0;
            background-size: cover;
        }
    }
    
    .standardCharacter {
        padding-top: 20vh;
        background-image: url(../img/accountOverview/standardAccountBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #0C0E1A;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .standardCharacterContent {
        padding: 10vh 0;

        .standardCharacterBox {
            transition: 1s;
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
                padding: 4vh 0;
            }
        }

        #standardCharacterBox0 {
            @media screen and (max-width: 991px) {
                background: radial-gradient(150.74% 97.24% at 29.77% 4.88%, #4E5374 0%, #1E233E 29.12%, #0C0E1A 100%);
            }

            &:hover {
                border: 3px solid #4E5374;
                background: radial-gradient(150.74% 97.24% at 29.77% 4.88%, #4E5374 0%, #1E233E 29.12%, #0C0E1A 100%);
            }

            .text-index-style {
                background: linear-gradient(94deg, #B7B7B7 1.68%, #666 94.93%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                width: fit-content;
            }
        }

        #standardCharacterBox1 {
            @media screen and (max-width: 991px) {
                background: radial-gradient(150.74% 97.24% at 29.77% 4.88%, #674F20 0%, #1E233E 29.12%, #0C0E1A 100%);
            }

            &:hover {
                border: 3px solid #674F20;
                background: radial-gradient(150.74% 97.24% at 29.77% 4.88%, #674F20 0%, #1E233E 29.12%, #0C0E1A 100%);
            }

            .text-index-style {
                background: linear-gradient(95deg, #FFCB45 7.94%, #E68717 97.44%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                width: fit-content;
            }
        }

        #standardCharacterBox2 {           
            @media screen and (max-width: 991px) {
                background: radial-gradient(150.74% 97.24% at 29.77% 4.88%, #25577B 0%, #1E233E 29.12%, #0C0E1A 100%);
            }

            &:hover {
                border: 3px solid #25577B;
                background: radial-gradient(150.74% 97.24% at 29.77% 4.88%, #25577B 0%, #1E233E 29.12%, #0C0E1A 100%);
            }

            .text-index-style {
                background: linear-gradient(92deg, #03FFE1 0.42%, #0349FF 99.4%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                width: fit-content;
            }
        }

        .borderLine {
            width: 120%;
            height: 1px;
            background-color: #3A4376;
            opacity: 1;
            margin: 3vh auto;
            margin-left: -10%;
        }
    }

    .depositWithdrawal {
        background-image: url(../img/accountOverview/depositWithdrawalBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10vh 0;

        .depositWithdrawalBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 3vh 0 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        .depositWithdrawalImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
                padding: 1vh;
            }

            img {
                width: 2.5vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        #depositWithdrawalImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv4 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv5 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286932 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv6 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #depositWithdrawalImgDiv7 {
            background: radial-gradient(104.68% 100% at 50% 100%, #16616C 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        .descSpacing {
            margin-top: 6vh;
        }

        .depositWithdrawalBoxSection {
            padding: 3vh 0 8vh 0;
        }

        .slick-slide {
            height: auto !important;
            padding: 1vh !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
        }
    }

    .paymentOptions {
        background-image: url(../img/accountOverview/paymentOptionsBg.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10vh 0;

        .accordionSection {
            border-top: 2px solid #3A4376;
        }

        .accordion-item {
            margin: 2vh 0;
            border-bottom: 2px solid #3A4376;
            background: transparent;
        }

        .accordionHeaderPadding {
            padding: 1.5vw;

            @media screen and (max-width: 991px) {
                padding: 2vh 3vh;
            }

            @media screen and (max-width: 767px) {
                padding: 2vh 2vh;
            }

            img {
                width: 2vw;
                margin-right: 2vw;

                @media screen and (max-width: 991px) {
                    width: 3vh;
                    margin-right: 3vh;
                }
            }
        }

        .accordion__button {
            background: transparent;
            padding: 0px;
        }

        .accordion__button::before {
            display: none !important;
        }

        .accordionIcon {
            color: #0349FF;
            transition: 1s;
        }

        .accordionIcon.active {
            transform: rotate(180deg);
        }
    }
}