.referralProgram {
    background-color: #0C0E1A;

    .whyBroker {
        padding: 25vh 0 5vh 0;

        .whyBrokerBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 3vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        .whyBrokerImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
                padding: 1vh;
            }

            img {
                width: 2.5vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 3.5vh;
                }
            }
        }

        #whyBrokerImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyBrokerImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyBrokerImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #286961 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyBrokerImgDiv3 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }
    }

    .header {
        background-image: url(../img/referralProgram/headerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 20vh 0;
        z-index: 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .incomeCalculate {
        background-image: url(../img/referralProgram/incomeCalculateBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }

        .vh-80-row {
            min-height: 80vh;
            margin-bottom: 0vh;
        }

        .accumulatedClientsDiv {
            border-radius: 29px;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #6B3079 0%, #0C0E1A 100%);
            padding: 2vh 3vh;

            span {
                color: #C0F;
            }
        }

        .incomeMonthDiv {
            border-radius: 29px;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #286961 0%, #0C0E1A 100%);
            padding: 2vh 3vh;

            span {
                color: #03F0FF;
            }
        }

        .rangeSliderSection {
            @media screen and (max-width: 991px) {
                padding: 5vh 0;
            }
        }

        .range-slider .range-slider__thumb {
            background: linear-gradient(93deg, #03FFE1 -2.84%, #0349FF 47.3%, #8227FA 93.58%) !important;
            width: 18px !important;
            height: 18px !important;
        }

        .range-slider .range-slider__range {
            background: #03FFE1 !important;
        }

        .single-thumb .range-slider__thumb[data-lower] {
            width: 0 !important;
        }

        .single-thumb .range-slider__range {
            border-radius: 6px !important;
        }
    }

    .whyBrokerVTGM {
        background-image: url(../img/referralProgram/whyBrokerVTGM.webp);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 10vh 0;

        .whyBrokerVTGMBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 0 0 3vh 0;
            height: 80% !important;

            @media screen and (max-width: 991px) {
                border-radius: 2vh;
                padding: 0 0 2vh 0;
            }
        }

        .whyBrokerVTGMBoxSection {
            margin: 5vh 0;
            display: flex;
            justify-content: center;
            align-items: stretch;
            height: 100%;
        }

        .whyBrokerVTGMImgDiv {
            border-radius: 1.5vw;
            border: 2px solid #2C3359;
            width: fit-content;
            padding: 1vw;
            margin-top: -5vh;

            @media screen and (max-width: 991px) {
                border-radius: 1.5vh;
                padding: 1vh;
                margin-top: -4vh;
            }

            img {
                width: 3vw;
                aspect-ratio: 1/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 5vh;
                }
            }
        }

        #whyBrokerVTGMBox0 {
            background: radial-gradient(89.27% 100% at 50% 0%, #582D8F 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #whyBrokerVTGMImgDiv0 {
            background: radial-gradient(104.68% 100% at 50% 100%, #5319A0 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyBrokerVTGMBox1 {
            background: radial-gradient(89.27% 100% at 50% 0%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #whyBrokerVTGMImgDiv1 {
            background: radial-gradient(104.68% 100% at 50% 100%, #294FB5 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyBrokerVTGMBox2 {
            background: radial-gradient(89.27% 100% at 50% 0%, #5C2727 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #whyBrokerVTGMImgDiv2 {
            background: radial-gradient(104.68% 100% at 50% 100%, #712727 0%, #1E233E 38.39%, #0C0E1A 100%);
        }

        #whyBrokerVTGMBox3 {
            background: radial-gradient(89.27% 100% at 50% 0%, #674F20 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #whyBrokerVTGMImgDiv3 {
            background: var(--Orange, radial-gradient(104.68% 100% at 50% 100%, #574518 0%, #1E233E 38.39%, #0C0E1A 100%));
        }

        #whyBrokerVTGMBox4 {
            background: radial-gradient(89.27% 100% at 50% 0%, #6B3079 0%, #1E233E 46.22%, #0C0E1A 100%);
        }

        #whyBrokerVTGMImgDiv4 {
            background: var(--Magenta, radial-gradient(104.68% 100% at 50% 100%, #681F7A 0%, #1E233E 38.39%, #0C0E1A 100%));
        }

        .slick-slide {
            height: auto !important;
            // padding: 1vh !important;
        }

        .slick-slide>div:first-child {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: stretch;
            margin: 5vh 0;
        }
    }

    .affiliateProgram {
        background-image: url(../img/referralProgram/affiliateBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .whatWeOffer {
        background-image: url(../img/referralProgram/whatWeOfferBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;
    }

    .fundManager {
        background-image: url(../img/referralProgram/fundManagerBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;
        
        @media screen and (max-width: 991px) {
            background-size: cover;
        }
    }

    .fundManagerContent {
        padding: 10vh 0;

        .fundManagerBox {
            border-radius: 2vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        #fundManagerBox0 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #286961 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #378A84;
            }
        }

        #fundManagerBox1 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #5C2727 0%, #1E233E 46.22%, #0C0E1A 100%), radial-gradient(109.78% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #8E4855;
            }
        }

        #fundManagerBox2 {
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #0033B9 0%, #1E233E 46.22%, #0C0E1A 100%);

            .text-index-style {
                color: #174CDB;
            }
        }
    }

    .franchiseModel {
        background-image: url(../img/referralProgram/franchiseModelBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0C0E1A;
        padding: 10vh 0;
    }

    .franchiseSlider {
        padding: 10vh 0;

        .franchiseBox {
            border-radius: 3vw;
            border: 2px solid #2C3359;
            background: radial-gradient(102.35% 97.24% at 29.77% 4.88%, #1E233E 0%, #0C0E1A 100%);
            padding: 5vh;
            margin-top: 5vh;

            @media screen and (max-width: 991px) {
                padding: 5vh;
                margin-top: 15vh;
                border-radius: 3vh;
            }

            .franchiseBoxImg {
                width: 80%;
                margin-top: -10vh;

                @media screen and (max-width: 991px) {
                    width: 100%;
                    margin-top: -20vh;
                }
            }
        }

        .sliderContentBox {
            padding-top: 2vh;

            .slick-dots {
                width: fit-content;
                bottom: 160%;
            }

            .slick-dots li {
                width: 8vw;
                height: 0.6vh;
                margin: 0 0.3vw;

                @media screen and (max-width: 991px) {
                    width: 15vw;
                }

                @media screen and (max-width: 300px) {
                    display: none;
                }
            }

            .slick-dots li button {
                width: 100%;
                height: 0.6vh;
                background-color: #404771;
                padding: 0;
                border-radius: 20vw;
            }

            .slick-dots .slick-active button {
                background: linear-gradient(110deg, #03FFE1 10.38%, #0349FF 52.66%, #8227FA 91.68%) !important
            }

            .slick-dots li button:before {
                content: none;
            }
        }

        .sliderArrowAbsolute {
            position: absolute;
            bottom: 20%;
            left: 0;

            @media screen and (max-width: 991px) {
                position: relative;
                bottom: 0;
                margin-top: 3vh;
            }
        }
    }
}